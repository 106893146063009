
.login_content {
  margin: 0 auto;
  padding: 25px 0 0;
  position: relative;
  text-align: center;
  min-width: 300px; }

.login_content a,
.login_content .btn-default:hover {
  text-decoration: none; }

.login_content a:hover {
  text-decoration: underline; }

.login_content form {
  margin: 20px 0;
  position: relative; }

.login_content form input[type="text"], .login_content form input[type="email"], .login_content form input[type="password"] {
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
  -o-box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
  box-shadow: 0 1px 0 #fff, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
  border: 1px solid #c8c8c8;
  color: #777;
  margin: 0 0 20px;
  width: 100%; }

.login_content form input[type="text"]:focus, .login_content form input[type="email"]:focus, .login_content form input[type="password"]:focus {
  -webkit-box-shadow: 0 0 2px #AA77B4 inset;
  -o-box-shadow: 0 0 2px #ed1c24 inset;
  box-shadow: 0 0 2px #A97AAD inset;
  background-color: #fff;
  border: 1px solid #A878AF;
  outline: none; }

#username {
  background-position: 10px 10px !important; }

#password {
  background-position: 10px -53px !important; }

.login_wrapper {
  right: 0;
  margin: 0 auto;
  margin-top: 5%;
  max-width: 350px;
  position: relative; }

.reset_pass {
  margin-top: 10px !important;
}
.login_content div .reset_pass {
  margin-top: 13px !important;
  margin-right: 39px;
  float: right;
}
.separator {
  border-top: 1px solid #D8D8D8;
  margin-top: 10px;
  padding-top: 10px;
}

