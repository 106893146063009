body {
  background-color: black;
  /*background-image: linear-gradient(gray, black);*/
  font-family: "Russo One", sans-serif;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

/** {*/
  /*outline: 1px solid #f00 !important;*/
/*}*/

/*html, body {*/
  /*height: 100%;*/
/*}*/

/* undo boostrap box-sizing: border-box; */
*, ::after, ::before {
  box-sizing: unset;
}

.header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  height: 2.5vh;
  font-family: "Arial", sans-serif;
}

.logo {
  height: 9vh;
  margin-left: 23vw;
}

.footer {
  height: 20vh;
  text-align: center;
  position: fixed;
  width: 100%;
}

.online-circle {
  padding: 2px 11px;
  border-radius: 100%;
  background-color: #32CD32;
}

.offline-circle {
  padding: 2px 11px;
  border-radius: 100%;
  background-color: #f40000;
}

.monitor-enter-active {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  -o-transform: scale(0.66);
  transform-origin: left top;
  transform: scale(0.66);
  transition: transform 1000ms;
}

.monitor-enter-done {
  transform-origin: left top;
  transform: scale(0.66);
}

.monitor-exit {
  transform-origin: left top;
  transform: scale(0.66);
}

.monitor-exit-active {
  -webkit-transform: scale(1.0);
  -ms-transform: scale(1.0);
  -o-transform: scale(1.0);
  transform-origin: left top;
  transform: scale(1.0);
  transition: transform 1000ms;
}

.monitor-exit-done {
  transform-origin: left top;
  transform: scale(1.0);
}

.aspectwrapper {
  display: inline-block; /* shrink to fit */
  width: 90%;           /* whatever width you like */
  position: relative;    /* so .content can use position: absolute */
}
.aspectwrapper::after {
  padding-top: 56.25%; /* percentage of containing block _width_ */
  display: block;
  content: '';
}
.content {
  position: absolute;
  top: 0; bottom: 0; right: 0; left: 0;  /* follow the parent's edges */
  outline: thin dashed green;            /* just so you can see the box */
}

button, input, optgroup, select, textarea {
    line-height: normal;
}

.key {
  float: right;
  text-align: center;
  display: flex;
  margin-top: 20px;
  margin-right: 25px;
}

.popover .arrow {
  display: none;
}

.dropdown-toggle::after {
  display: none;
}

.custom-select {
  height: 20px;
  padding: 0.1vh;
  width: unset;
  color: white;
  background: black;
}
