@font-face {
    font-family: 'russo_oneregular';
    src: url('./fonts/russoone-regular-webfont.woff2') format('woff2'),
         url('./fonts/russoone-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
    background-color: #1F1F1F;
    font-family: 'russo_oneregular';
    color: White;
    font-size: 18px;
    font-weight: 400;
}

.rack-block {
    color: white;
    font-family: 'russo_oneregular';
    position: relative;
    display: block;
    background-color: #000000;
    border-radius: 24px;
    width: 700px;
    margin: 5px;
    float: left;
}

.ess-rack {
    position: relative;
}

.online.grayText {
    color: #aba6a6;
}

/* Transform when hiding ess container map. */
.ess-container.minimized {
    display: none;
}

/* Rack column names. */
.rack-column-name {
    position: absolute;
    top: 0;
    text-align: center;
    color: White;
    width: 215px;
    height: 20px;
    font-size: 16px;
}

.rack-column-name-A {
    left: 72px;
}

.rack-column-name-B {
    right: 57px;
}

.inline-table {
    display: inline-table;
}

.rack-headings {
    position: relative;
    text-align: center;
}

.rack-selector {
    display: inline-block;
    position: relative;
    margin-left: 0;
}

.rack-name:hover {
    cursor: pointer;
}

.rack-name {
    font-size: 22px;
    position: relative;
    display: inline-block;
    left: 0px;
    margin-top: 10px;
    z-index: 3;
}

.rack-min-max-caret {
    font-size: 15px;
    display: inline-block;
    font-weight: bold;
    z-index: 7;
    margin-left: 10px;
}

.rack-min-max-caret:hover > span {
    color: white;
    border-color: white;
    font-weight: 900;
    cursor: pointer;
}

.bmu-block,
.inverter-block {
    box-sizing: content-box;
    border: 3px solid White;
    border-radius: 6px;
    background: black;
    text-align: center;
    padding: 5px;
    margin: 0;
    height: 60px;
}

.bmu-block {
    width: 190px;
    height: 60px;
}

.inverter-block {
    width: 50px;
    height: 60px;
}

.bmu-metal-rack {
    position: absolute;
    left: 69px;
    top: 30px;
}

.string-rack {
  position: relative;
  /* top: 5vh; */
}

.caret-span {
    position: absolute;
    top: 0;
    left: auto;
}

/* ESS container map */
.ess-container {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    height: fit-content;
    width: fit-content;
    margin-top: 15px;
    margin-bottom: 20px;
}

.ess-container-map {
    display: inline-table;
    border: 3px solid #666666;
    border-radius: 6px;
    text-align: center;
    width: 285px;
}

.ess-container-map-title {
    text-align: center;
    vertical-align: middle;
    color: #666666;
    font-size: 20px;
    height: 33px;
    padding-top: 3px;
    white-space: nowrap;
    overflow: hidden;
}

.ess-container-map-cell {
    color: #666666;
    border: 3px solid #666666;
    border-radius: 6px;
    display: inline-table;
    margin: 0;
    width: 100%;
    height: 25px;
}

.ess-container-map-cell:hover,
.ess-container-map-cell:hover > span,
.ess-container-map-cell-config:hover,
.ess-container-map-cell-config:hover > span {
    color: white;
    border-color: white;
}

.ess-container-map-selected-config:hover,
.ess-container-map-selected-config:hover > span {
    color: #666666;
    border-color: #666666;
}

.text-align-middle {
    text-align: center;
    vertical-align: middle;
}

.ess-container-map-selected {
    color: White;
    border: 3px solid White;
    border-radius: 6px;
}

.ess-container-door {
    position: absolute;
    top: 40px;
    left: -5px;
    height: 30px;
    width: 1px;
    border-collapse: collapse;
    border: 3px solid #666666;
}

.ess-container-map-table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    /* border: 3px solid #666666; */
}

.ess-container-map-table-td {
    border-collapse: collapse;
    width: 20%;
    height: 25px;
    cursor: pointer;
}

/* Inverter and BMU column containers/tables. */
.bmu-metal-rack-table-layout-fixed {
    display: table;
    border-collapse: collapse;
    z-index: 2;
}

.bmu-metal-rack-row {
    display: table-row;
}

.ess-metal-rack-slot-label{
    color: #B7B7B7;
    z-index: 1;
}

.bmu-rack-slot {
    display: table-cell;
    border: 5px solid #B7B7B7;
    padding: 2px;
    width: 215px;
    height: 84px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    vertical-align: middle;
}

/* string rack containers/tables */
.string-rack-table-layout-fixed {
  display: table;
  border-collapse: collapse;
  z-index: 2;
}

.string-rack-row {
  display: table-row;
}

.string-rack-slot-label{
  position: relative;
  color: #B7B7B7;
  z-index: 1;
  font-family: "Arial", sans-serif;
  font-size: 18px;
  font-weight: bold;
  top: 10px;
}

.string-rack-slot {
  display: table-cell;
  border: 5px solid #B7B7B7;
  padding: 2px;
  width: 315px;
  height: 60px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
}

.string-cell {
  position: absolute;
  height: 60px;
  width: 320px;
}

.string-voltage {
  position: relative;
  display: flex;
  float: right;
  top: 30px;
  font-family: "Arial", sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
}

.string-current {
  position: relative;
  display: flex;
  float: left;
  top: 30px;
  font-family: "Arial", sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
}

.inverter-op-status {
    position: absolute;
    left: 7px;
    top: 6px;
    font-size: 14px;
    color: #ff2121;
}

.inverter-status-circle {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    position: absolute;
    left: 25px;
    top: 10px;
    background-color: #434343FF; /* Default color of .online or .offline do not get applied. */
}

.inverter-status-circle.online {
    background-color: #00FF00;
}

.inverter-status-circle.offline {
    background-color: #ff2121;
}

.inverter-lock-icon {
    position: absolute;
    left: 45px;
    top: 10px;
    font-size: 12px;
    color: red;
}

.locked_lp {
    opacity: 0.8;
    text-shadow: -1px 1px 0 white,
                 1px 1px 0 white,
                 1px -1px 0 white,
                 -1px -1px 0 white;
}

.bmu-dot-container {
    position: absolute;
    height: 10px;
    width: 10px;
    top: 0px;
}

.bmu-dot-container-A {
    left: 0;
}

.bmu-dot-container-B{
    left: 178px;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.bmu-status-circle {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #434343FF; /* Default color of .online or .offline do not get applied. */
}

.bmu-status-circle.online {
    background-color: #00FF00;
}

.bmu-status-circle.offline{
    background-color: #ff2121;
}

.inverter-op-status.offline, .fa-lock.offline {
    color: #ff2121 !important;
    opacity: 0.5;
}

.bmu-block-number {
    height: 15px;
    width: 15px;
    position: absolute;
    top: 48px;
    font-size: 12px;
    z-index: 5;
}

.bmu-block-number-A {
    right: 178px;
}

.bmu-block-number-B {
    left: 178px;
}

/* BMU block readings and soc. */
.bmu-block-inner {
    position: relative;
}

.bmu-state {
    font-size: 11.75px;
    position: absolute;
    top: 15px;
    width: 20px;
    height: 15px;
}

.bmu-state-A {
    left: -2px;
}

.bmu-state-B {
    left: 172px;
}

.fan-state {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 334px;
    top: -5px;
}

/* Make a div spin: https://code-boxx.com/rotate-spin-image-html-css/ */
@keyframes spinning {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
}
.spin {
    animation-name: spinning;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.fan-off-exclamation {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 40px;
    top: -6px;
    font-size: 30px;
    color: #FF2121;
}

.fa-arrows-v.bmu-state-icon-A,
.fa-long-arrow-up.bmu-state-icon-A,
.fa-long-arrow-down.bmu-state-icon-A {
    position: absolute;
    left: 4px;
}

.fa-arrows-v.bmu-state-icon-B,
.fa-long-arrow-up.bmu-state-icon-B,
.fa-long-arrow-down.bmu-state-icon-B {
    position: absolute;
    left: 9px;
}

.fa-ban.bmu-state-icon-A {
    position: absolute;
    left: 2.5px;
}

.fa-ban.bmu-state-icon-B {
    position: absolute;
    left: 6.5px;
}

.fa-exclamation-triangle.bmu-state-icon-A {
    position: absolute;
    left: 2px;
}

.fa-exclamation-triangle.bmu-state-icon-B {
    position: absolute;
    left: 6px;
}

.reading-view-mode {
    font-size: 12px;
    position: absolute;
    left: 58px;
    top: 2.5px;
}

.reading-selector-container {
    width: 98%;
    text-align: center;
    text-align-last: center;
    position: absolute;
    top: 32px;
    left: 0;
}

.selected-bmu-reading {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0 black;
    background: black;
    width: 100%;
    color: white;
    font-family: Arial, sans-serif;
    font-weight: 700;
    text-align: center;
    text-align-last: center;
    font-size: 17px;
    position: absolute;
    left: 0;
    top: 0;
    /* z-index: 4; */
}

.selected-bmu-reading-value {
    width: 100%;
    height: 25px;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
}

.selected-bmu-reading-current-A {
    width: 50%;
    height: 25px;
    position: absolute;
    left: 10px;
    top: 0;
    overflow: visible;
}

.selected-bmu-reading-voltage-A {
    width: 50%;
    height: 25px;
    position: absolute;
    left: 105px;
    top: 0;
    overflow: visible;
}

.selected-bmu-reading-current-B {
    width: 50%;
    height: 25px;
    position: absolute;
    left: 0;
    top: 0;
    overflow: visible;
}

.selected-bmu-reading-voltage-B {
    width: 50%;
    height: 25px;
    position: absolute;
    left: 90px;
    top: 0;
    overflow: visible;
}

.reading-selector {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0 black;
    background: transparent;
    width: 100%;
    color: black;
    font-family: Arial, sans-serif;
    font-weight: 700;
    text-align: left;
    text-align-last: center;
    font-size: 17px;
    position: absolute;
    left: 0;
    top: 0;
    color:transparent;
}

.reading-selector-A {
    left: 9px;
}

.reading-selector-B {
    left: 0;
}

.reading-selector option,
.inverter-reading-selector option {
    background: black;
    color: white;
    font-weight: 700
}

.reading-selector:focus,
.inverter-reading-selector:focus {
    outline: none;
}

.reading-selector-mode-container {
    width: 100%;
    display: inline-table;
    position: absolute;
    height: 35px;
    top: 10px;
    left: 0;
}

.reading-selector-mode {
    font-size: 20px;
    width: 100%
}

.negative-reading {
    color: #FF9900 !important;
}

.positive-reading {
    /* color: white; */
    /* color: darkgray; */
    /* color: lightgray; */
    /* color: #FF9900; */ /* orange */
    color: #00FF00 !important; /* green */
}

/* Inverter Reading Selector */
.inverter-reading-selector-container {
    width: 100%;
    text-align: center;
    text-align-last: center;
    position: absolute;
    top: 30px;
    left: 0;
}

.inverter-reading-selector {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0 black;
    background: transparent;
    width: 58px;
    color: black;
    font-family: Arial, sans-serif;
    font-weight: 700;
    text-align: left;
    text-align-last: center;
    font-size: 13px;
    position: absolute;
    left: 0;
    top: 0;
    color: transparent;
}

.selected-inverter-reading {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0 black;
    background: black;
    width: 100%;
    color: white;
    font-family: Arial, sans-serif;
    font-weight: 700;
    text-align: center;
    text-align-last: center;
    font-size: 13px;
    position: absolute;
    left: 0;
    top: 0;
    max-height: 38px;
    overflow: hidden;
}

.selected-inverter-reading-value {
    width: 100%;
    height: 25px;
    display: inline-block;
    position: relative;
    word-wrap: break-word;
}

.soc-bar {
    height: 3px;
    margin-bottom: -2px;
    border-radius: 5px;
}

.soc {
    width: 88%;
    background-color: #434343FF;
    position: absolute;
    top: 57px;
    left: 3px;
}

.soc-A {
    left: 20px;
}

.soc-B {
    right: 20px;
}

.soc-percent {
    background-color: #00FF00;
}

/* BMU string soc bars. */
.bmu-cell-voltage-bar {
    width: 8px;
    height: 100%;
    border-radius: 6px;
}

.cell-voltage {
    height: 110%;
    background-color: #434343FF;
    margin-left: 2px;
    position: relative;
}

.cell-voltage-percent {
    background-color: #00FF00;
    position: absolute;
    bottom: 1px;
    border-radius: 0 0 6px 6px;
    width: 6px;
}

.cell-voltage-left {
    left: 1px;
}

.cell-voltage-right {
    left: 1px;
}

.cell-voltage-percent-shape {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: transparent;
    clip-path: inset(4% 4% 0% 5% round 6px);
}

.cell-voltage-percent-selected {
    border-radius: 6px;
    border: 1px solid white;
}

.cell-voltage-percent-curved-top {
    border-radius: 6px;
}

.bmu-cell-info-table {
    height: 35px;
    width: 170px;
    border-collapse: collapse;
    margin-top: -5px;
}

.bmu-cell {
    height: 15px;
    border-collapse: collapse;
    padding: 0;
}

.bmu-cell-table-row {
    font-family: Arial, sans-serif;
    font-size: 6px;
}

.bmu-cell-table-header-td {
    padding: 0;
    margin: 0;
}

.bmu-cell-headers {
    height: 7px;
    padding-bottom: 0;
}

.bmu-cell-header {
    padding: 0;
    margin: 0;
    height: 7px;
}

.bmu-cell-info {
    display: inline-table;
    position: absolute;
    height: 25px;
    top: 0;
}

.bmu-cell-info-A {
    left: 20px;
}

.bmu-cell-info-B {
    left: 0;
}

/* SDS block and wire connector. */
.sds-container {
    position: absolute;
    width: 700px;
    height: 60px;
    top: 1119px;
    left: 0;
}

.sds {
    height: 100%;
    position: absolute;
    top: 0;
}

.sds-block {
    border: 2px solid White;
    border-radius: 5px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    width: 165px;
    height: 30px;
    position: absolute;
    top: 20px;
}

.sds-block-A {
    left: 170px;
}

.sds-block-B {
    left: 380px;
}

.sds-block-text {
    font: 400 13.3333px Arial; /* Make it the same font as the selector. */
    width: 100%;
    text-align: center;
    vertical-align: middle;
    margin-top: 5.5px;
}

.sds-wire {
    width: 10px;
    height: 20px;
    position: absolute;
    top: 0;
}

.sds-wire-A {
    border-right: 2.5px solid #00FFFF;
    border-radius: 0 0 3px 0;
    left: 235px;
}

.sds-wire-B {
    border-left: 2.5px solid #00FFFF;
    border-radius: 0 0 0 3px;
    left: 465px;
}

/* SDS selector */
.sds-select-div {
    position: relative;
}
.sds-block-select-inner {
    text-align: center;
    text-align-last: center;
    position: absolute;
    top: 20px;
}
.sds-block-A-select {
    left: 115px;
}
.sds-block-B-select {
    right: 115px;
}
.sds-selector {
    border: 2px solid white;
    border-radius: 3px;
    background: black;
    width: 200px;
    height: 30px;
    color: White;
    font-family: Arial, sans-serif;
    font-weight: 700;
    text-align: center;
    text-align-last: center;
}

.sds-selector option {
    font-weight: 700
}

.sds-wire-select {
    width: 3px;
    height: 20px;
    position: absolute;
    top: 0;
}

/* Button to set a newly selected SDS. */
.sds-set-button {
    text-align: center;
    text-align-last: center;
    position: absolute;
    top: 20px;
    font-size: 12px;
    cursor: pointer;
    width: 35px !important;
    height: 30px !important;
    color: white;
    background-color: #000FFF;
    border: none;
    border-radius: 6px;
    padding: 5px 5px;
    text-decoration: none;
}

.sds-set-button:disabled {
    display: none;
    /* background-color: #0000CD;
    opacity: 0.5;
    cursor: not-allowed; */
}

.sds-set-button-A {
    left: 320px;
}

.sds-set-button-B {
    right: 75px;
}

/* BMU and Inverter absolute positioning. */
.bmu-block,
.inverter-block {
    position: absolute;
    z-index: 5;
}

/* Offline styling */
.offline,
.offline > * {
    color: #474747 !important;
    border-color: #474747;
}

.soc-percent.offline,
.bar-burst.offline,
.cell-voltage-percent.offline {
    opacity: .3;
}

.button:hover,
.sds-set-button:hover {
    background-color: #0000CD;
}

.ess-container-config {
    position: relative;
    width: 60%;
}

 /* Don't let the user be able to highlight/select anything in the ess container map.  */
.ess-container > *,
.ess-container-config > * {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ess-container-map-config {
    position: relative;
    display: inline-table;
    /* border: 2px solid #666666; */
    border-radius: 6px;
    text-align: center;
    width: 450px;
}

.ess-container-map-table-config {
    border-collapse: inherit;
    border-spacing: 0 !important;
    width: 100%;
    table-layout: fixed;
    border: 2px solid #666666;
    border-radius: 6px;
    max-width: 450px;
}

.ess-container-map-table-td-config {
    border-collapse: collapse;
    border-spacing: 0;
    width: 20%;
    height: 30px;
    padding: 0;
    cursor: pointer;
}

.ess-container-map-title-td-config {
    border-collapse: collapse;
    border-spacing: 0;
    height: 30px;
}

.ess-container-title-span:hover {
    color: white;
    cursor: pointer;
}

.cell-config-container {
    position: relative;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;

}

.ess-container-map-cell-config {
    color: #666666;
    border: 2px solid #666666;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    margin: 0;
    width: 50%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    padding-top: 1px;
}

.cell-top {
    /* margin-top: -5px; */
    width: 50%;
}

.cell-bottom {
    /* margin-bottom: -5px; */
    width: 50%;
}

.cell-top.cell-A {
    border-radius: 0 0 0 6px;
    position: absolute;
    left: 0;
    top: 0;
}

.cell-bottom.cell-A {
    border-radius: 0 6px 0 0;
    position: absolute;
    right: 0;
    top: 0;
}

.cell-top.cell-B {
    border-radius: 0 0 6px 0;
    position: absolute;
    right: 0;
    top: 0;
}

.cell-bottom.cell-B {
    border-radius: 6px 0 0 0;
    position: absolute;
    left: 0;
    top: 0;
}

.ess-container-map-selected-config {
    color: black;
    background-color: white;
    border-radius: 3px;
}

.ess-container-map-tr-config {
    border-collapse: collapse;
    border-spacing: 0;
}

/* Cell Voltage Bar Burst. */
.bar-burst-outer {
    position: absolute;
    top: 0;
}

.bar-burst-inner {
    position: relative;
    width: 12px;
    height: 12px;
    z-index: 8;
    background-color: transparent;
    clip-path: polygon(37% 1%, 40% 30%, 23% 15%, 33% 38%, 3% 30%, 25% 48%, 2% 62%, 31% 58%, 5% 80%, 49% 63%, 91% 77%, 66% 58%, 97% 62%, 71% 48%, 96% 36%, 70% 38%, 87% 16%, 61% 31%, 61% 12%, 50% 26%);
}

.bar-burst {
    position: absolute;
    top: 0;
    width: 12px;
    height: 12px;
    z-index: 7;
    background-color: #ff2121;
}

.reading-view-mode-toggle-switch {
    position: absolute;
    top: 140px;
    left: 30px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 25px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black/* #337ab7 */;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 6px;
    border: 2px solid white;
    box-sizing: border-box;
}

.slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 0px;
    bottom: 0px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border: 2px solid black/* #337ab7 */;
    border-radius: 6px;
    box-sizing: border-box;
    z-index: 4;
}

input:checked + .slider {
    background-color: #2e6da4;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2e6da4;
}

input:checked + .slider:before {
    -webkit-transform: translateX(27px);
    -ms-transform: translateX(27px);
    transform: translateX(27px);
    border: 2px solid #2e6da4;
}

.leftToggleLabel {
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: 10px;
    z-index: 3;
    font-family: 'Times New Roman', Times, sans-serif;
    color: #c0c0c0;
}

.rightToggleLabel {
    position: absolute;
    top: 5px;
    right: 1px;
    font-size: 10px;
    z-index: 3;
    font-family: 'Times New Roman', Times, sans-serif;
    color: #c0c0c0;
}

.value-blink {
    /* animation: name duration timeing-function delay iteration-count direction fill-mode; */
    animation: blink 800ms ease-out 0ms 1 alternate none;
    -webkit-animation: blink 800ms ease-out 0ms 1 alternate none; /* Safari and Chrome */
    animation-timing-function: step-start;
}

.empty-rack {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

@keyframes blink {
    from {
        color: white;
    }
    to {
        color: lightgray;
    }
}

@-webkit-keyframes blink {
    from {
        color: white;
    }
    to {
        color: lightgray;
    }
}

.overview-container { 
  width: 250px;
  height: 250px;
  margin: 100px;
}

.overview-rack { 
  width: 250px;
  height: 75px;
  position: absolute;
  border-radius: 6px;
  transform-style: preserve-3d;
}

.face {
  width: 250px;
  height: 75px;
  position: absolute;
  background: rgb(0, 0, 0);
  opacity: 1;
  border: 1px solid #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.long-face {
  width: 250px;
  height: 250px;
  position: absolute;
  background: rgb(0, 0, 0);
  opacity: 1;
  border: 1px solid #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.short-face {
  width: 75px;
  height: 250px;
  position: absolute;
  background: rgb(0, 0, 0);
  opacity: 1;
  border: 1px solid #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overview-readings-face {
  position: absolute;
  transform: translateY(-37.5px) rotateX(90deg);
}

.overview-bmu-soc-face { 
  position: absolute;
  transform: translateZ(37.5px);
}

.back-face {
  position: absolute;
  transform: translateZ(-37.5px) rotateY(180deg);
  background: rgb(0, 0, 0, 0.4);
}

.bottom-face {
  position: absolute;
  transform: translateY(212.5px) rotateX(90deg);
  background: rgb(0, 0, 0, 0.4);
}

.left-face {
  position: absolute;
  transform: translateX(-37.5px) rotateY(-90deg);
}

.right-face {
  position: absolute;
  transform: translateX(212.5px) rotateY(90deg);
}

.container-3d-box{
  width: 350px;
  margin-left: 175px;
  margin-top: 50px;
  position: relative;
  border-radius: 8px;
  background: rgb(50, 50, 50);
  transform-style: preserve-3d;
  transform: rotateY(-5deg) rotateX(10deg);
}