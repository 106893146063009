@font-face {
  font-family: 'russo_oneregular';
  src: url('./fonts/russoone-regular-webfont.woff2') format('woff2'),
       url('./fonts/russoone-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.outer-container {
  background-color: black;
  border-radius: 10px;
  margin: 0.5% 0 0 0.5%;
  overflow: hidden;
}

.mini {
  pointer-events: none;
}

.flex-container {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.site-layout {
  background-color: #000000;
  width: auto;
}

.select-readings {
  border: 0 rgba(0,0,0,0);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline;
  background: rgba(0,0,0,0);
  text-align: left;
  text-align-last: center;
  width: 104px;
}

select:focus{
  outline: none;
}

.apparent-module {
  text-align: center;
  font-family: 'russo_oneregular';
  color: white;
  line-height: 1.42857143;
  position: absolute;
  z-index: 5;
}

.apparent-module-label {
  text-align: center;
  font-family: 'russo_oneregular';
  color: white;
  line-height: 1.42857143;
  font-size: 24px;
  position: absolute;
}

.solar-field {
  font-size: 24px;
}

.solar-readings {
  height: 61px;
  width: 136px;
  border: 2px solid white;
  border-radius: 35px;
  background: black;
  padding-top: 5px;
  margin-left: 28px;
  top: 20px;
  position: relative;
  z-index: 10;
}

.solar-panels {
  margin-top: -85px;
  margin-left: -1px;
  z-index: 5;
  position: relative;
}

.solar-panel {
  width: 164px;
  height: 34px;
  -webkit-transform: skew(9deg);
  -moz-transform: skew(9deg);
  -o-transform: skew(9deg);
  transform: skew(9deg);
  border: 2px solid white;
  background: black;
}

.solar-svg {
  transform: scale(0.85);
  position: absolute;
  top: 20px;
  left: 37px;
  background: #000;
  z-index: 5;
  height: 120px;
  width: 120px;
}

.mid-panel {
  margin: -21px 13px;
}

.front-panel {
  margin: 2px 27px;
}

.solar-name {
  position: relative;
  left: 10px;
  top: -18px;
  z-index: 10;
}

.solar-icon-1 {
  fill: #f8ff21;
}

.solar-outer-container {
  text-align: center;
  width: 200px;
}

.arrow-up {
  width: 0; 
  height: 0; 
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 30px solid;
  position: relative;
  left: 70px;
  top: 80px;
}

.solar-background-box {
  width: 164px;
  height: 68px;
  border: 2px solid white;
  background: black;
  position: relative;
  z-index: 7;
}

.sun-icon {
  position: absolute;
  top: -80px;
  left: 73px;
  transform: scale(0.5);
  z-index: -1;
}

.sun-fill {
  fill: white;
}



.readings {
  font-family: "Arial", sans-serif;
  font-size: 18px;
  font-weight: bold;
}

.negative-readings {
  color: #ff9900;
}
.positive-readings {
  color: #00ff00ff;
}
.regulate-readings {
  color: #00ffff;
}

.apparent-module-battery {
  text-align: center;
  font-family: 'russo_oneregular';
  color: white;
  position: absolute;
  width: 120px;
  height: 125px;
  margin-top: 8px;
  z-index: 5;
  line-height: 1.42857143;
  margin-left: -40px;
}


.battery-name {
  font-size: 18px;
  margin-top: 3px;
  margin-bottom: 2px;
  min-height: 25px;
}

.battery-name-bottom {
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 2px;
  min-height: 25px;
}

.battery {
  height: 125px;
  margin-top: 8px;
  z-index: 5;
}

.battery-outer-container {
  text-align: center;
  width: 200px;
}

.battery-inner-container {
  display: inline-block;
  width: 120px;
}

.battery-soc {
  padding-top: 8px;
}

.battery-soc-cell {
  width: 6px;
  height: 25px;
  display: inline-block;
  border-radius: 2px;
  margin: 2px;
}

.battery-soc-cell-red{
  background-color: red;
}

.battery-soc-cell-yellow{
  background-color: yellow;
}

.battery-soc-cell-grey{
  background-color: #414141;
}

.battery-soc-cell-full {
  background-color: #00ff00ff;
}

.battery-soc-cell-empty {
  background-color: #414141;
}

.der-name {
  font-size: 18px;
  margin-top: 5px;
}

.battery-block {
  border: 3px solid white;
  border-radius: 12px;
  height: 109px;
  background: black;
}

.battery-terminal {
  width: 9px;
  height: 4px;
  background-color: white;
  border-top-left-radius: 3px;  /* 100px of height + 10px of border */
  border-top-right-radius: 3px; /* 100px of height + 10px of border */
  border: 3px solid white;
  border-bottom: 0;
}

.left-terminal {
  display: inline;
  float: left;
  position: relative;
  left: 14%;
}

.right-terminal {
  position: relative;
  left: 75%;
}

.cooler-interior-box {
  border: 3px solid white;
  border-radius: 12px;
  height: 109px;
  background: black;
}

.apparent-module {
  text-align: center;
  font-family: 'russo_oneregular';
  color: white;
  position: absolute;
}

/*** Meter ***/

.meter-name {
  font-size: 18px;
  margin-top: 5px;
  min-height: 25px;
}

.meter-readings {
  font-family: "Arial", sans-serif;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  top: 38%;
}

.meter {
  z-index: 10;
}

.meter-aggregate-main-wrapper {
  width: 114px;
  height: 114px;
  background: black;
  margin: 1px 1px;
}

.meter-aggregate-wrapper {
  width: 114px;
  height: 114px;
  background: black;
  margin: 1px 1px;
  float: left;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.meter-aggregate-1 {
  width: 114px;
  height: 114px;
  position: absolute;
  background: black;
  -moz-border-radius: 55px;
  -webkit-border-radius: 55px;
  border-radius: 72px;
  border: 3px solid white;
  margin: 1px 1px;
}

.meter-aggregate-2 {
  width: 104px;
  height: 104px;
  position: absolute;
  background: black;
  -moz-border-radius: 55px;
  -webkit-border-radius: 55px;
  border-radius: 72px;
  border: 3px solid white;
  margin: 1px 1px;
}

.meter-aggregate-logo {
  position: absolute;
  top: 69px;
  left: 39px;
  width: 35px;
  height: 35px;
  z-index: 5;
}

.meter-block {
  width: 114px;
  height: 114px;
  background: black;
  -moz-border-radius: 55px;
  -webkit-border-radius: 55px;
  border-radius: 66px;
  border: 3px solid white;
  margin: 1px 1px;
}

.meter-square {
  width: 228px;
  height: 60px;
  background: black;
  -moz-border-radius: 55px;
  -webkit-border-radius: 55px;
  border-radius: 30px;
  border: 3px solid white;
  margin-top: -65px;
}

.meter-icon {
  transform: scale(0.5);
  position: absolute;
  top: 23px;
  left: -43px;
}

.der-name {
  font-size: 18px;
  margin-top: 5px;
}

.transformer {
  width: 200px;
  height: 125px;
  margin-top: 8px;
  z-index: 5;
}

.transformer-name {
  font-size: 18px;
  margin-top: -4px;
  margin-bottom: 5px;
}


.transformer-container {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.transformer-coil {
  width: 2px;
  height: 50px;
  background-color: white;
  border-top-left-radius: 3px;  /* 100px of height + 10px of border */
  border-top-right-radius: 3px; /* 100px of height + 10px of border */
  border: 1px solid white;
  border-bottom: 0;
}

.left-coil {
  display: inline;
  float: left;
  position: relative;
  left: 18%;
}

.middle-coil {
  float: left;
  position: relative;
  left: 45%;
}

.right-coil {
  position: relative;
  left: 80%;
}

.coil-disc {
  width: 19px;
  height: 3px;
  background-color: white;
  border-top-left-radius: 10px;  /* 100px of height + 10px of border */
  border-top-right-radius: 10px; /* 100px of height + 10px of border */
  border: 1px solid white;
  margin-top: 5px;
  margin-left: -10px;
}

.transformer-coils {
  width: 120px;
}

.transformer-block {
  border: 2px solid white;
  border-radius: 12px;
  height: 55px;
  width: 120px;
  background: black;
}

.transformer-status {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.transformer-subsite-symbol {
  position: relative;
  float: right;
  right: 8px;
  top: -4px;
  font-size: 12px;
}

.transformer-readings {
  font-family: "Arial", sans-serif;
  font-size: 19px;
  font-weight: bold;
  position: absolute;
  top: 63.5%;
  width: 60%;
}


.horizontal-connector {
  height: 10px;
  width: 130px;
  background: #32CD32;
  position: absolute;
  margin: 13px 13px;
  z-index: 0;
  /* top: 60%; */
}

.vertical-connector {
  height: 100px;
  width: 10px;
  background: #32CD32;
  position: absolute;
  margin: 13px 13px 13px 13px;
}

.overlap-connector {
  display: inline-block;
  border: 10px solid #32CD32;
  width: 20px;
  height: 20px;
  background: rgba(0,0,0,0);
  position: absolute;
  margin: 13px 13px 13px 13px;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px; 
  border-bottom: 0;
}


#arrowAnim {
  align-items: center;
}

.arrow {
  margin-top: 2px;
  width: 3px;
  height: 3px;
  border: 2px solid;
  border-color: white transparent transparent white;
  transform: rotate(-45deg);
}

.rightArrowSliding {
  animation: slideRight 2s linear infinite;
}

@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(-25px);
  }
  20% {
    opacity: 1;
    transform: translateX(-15px);
  }
  40% {
    opacity: 1;
    transform: translateX(-5px);
  }
  60% {
    opacity: 1;
    transform: translateX(5px);
  }
  80% {
    opacity: 1;
    transform: translateX(15px);
  }
  100% {
    opacity: 0;
    transform: translateX(25px);
  } 
}

.leftArrowSliding {
  animation: slideLeft 2s linear infinite;
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(25px);
  }
  20% {
    opacity: 1;
    transform: translateX(15px);
  }
  40% {
    opacity: 1;
    transform: translateX(5px);
  }
  60% {
    opacity: 1;
    transform: translateX(-5px);
  }
  80% {
    opacity: 1;
    transform: translateX(-15px);
  }
  100% {
    opacity: 0;
    transform: translateX(-25px);
  } 
}

.upArrowSliding {
  animation: slideUp 2s linear infinite;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  20% {
    opacity: 1;
    transform: translateY(15px);
  }
  40% {
    opacity: 1;
    transform: translateY(5px);
  }
  60% {
    opacity: 1;
    transform: translateY(-5px);
  }
  80% {
    opacity: 1;
    transform: translateY(-15px);
  }
  100% {
    opacity: 0;
    transform: translateY(-25px);
  } 
}

.downArrowSliding {
  animation: slideDown 2s linear infinite;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-25px);
  }
  20% {
    opacity: 1;
    transform: translateY(-15px);
  }
  40% {
    opacity: 1;
    transform: translateY(-5px);
  }
  60% {
    opacity: 1;
    transform: translateY(5px);
  }
  80% {
    opacity: 1;
    transform: translateY(15px);
  }
  100% {
    opacity: 0;
    transform: translateY(25px);
  } 
}

.connector {
  height: 10px;
  width: 10px;
  background: #32CD32;
  position: absolute;
  margin: 15px 15px 15px 15px;
}

.mini-transformer {
  width: 200px;
  height: 125px;
  z-index: 5;
}

.mini-transformer-container {
  display: inline-block;
  text-align: center;
}

.mini-transformer-name {
  font-size: 18px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.mini-transformer-readings {
  font-family: "Arial", sans-serif;
  font-size: 19px;
  font-weight: 700;
  position: relative;
  top: 27%;
}

/* Electric Bus Dispenser */

.apparent-module-bus-dispenser {
  text-align: center;
  font-family: 'russo_oneregular';
  color: #fff;
  position: absolute;
  width: 120px;
  margin-top: 7px;
  z-index: 5;
  line-height: 1.42857143;
  margin-left: -52px;
}

.bus-dispenser-name {
  font-size: 18px;
  margin-bottom: 2px;
  min-height: 25px;
  margin-top: -3px;
  margin-left: 0px;
  width: 150px;
}

.bus-dispenser-outer-container {
  text-align: center;
  width: 200px;
}

.bus-dispenser-inner-container {
  display: inline-block;
  width: 120px;
}

.bus-dispenser-soc-cell {
  width: 6px;
  height: 17px;
  display: inline-block;
  border-radius: 2px;
  margin: 2px;
}

.bus-dispenser-block {
  position: absolute;
  top: 111px;
  left: 12px;
  border: 3px solid #fff;
  border-radius: 12px;
  height: 60px;
  background: #000;
  width: 120px;
}

.bus-dispenser-hbar {
  position: absolute;
  top: 22px;
  left: 18px;
  background: white;
  width: 112px;
  height: 8px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.bus-dispenser-vbar {
  top: 23px;
  width: 8px;
  height: 115px;
  position: absolute;
  left: 18px;
  background: white;
}

.bus-dispenser-charger {
  position: absolute;
  left: 56px;
  top: 32px;
  width: 39px;
  height: 5px;
  background: white;
  border-radius: 100px;
}

.electric-bus {
  transform: scale(0.85);
  position: absolute;
  top: 20px;
  left: 11px;
}

.charging-background {
  background-color: #32CD32;
}

.bus-battery-soc-cell {
  width: 4px;
  height: 29px;
  display: inline-block;
  border-radius: 2px;
  margin: 1px;
}

.bus-soc {
  position: absolute;
  top: 30px;
  left: 20px;
}

.bus-icon-1 {
  fill: #56deff;
}

.bus-icon-2 {
  fill: #56deff;
}

/* PDU */
.pdu {
  border: 2px solid white;
  border-radius: 12px;
  height: 75px;
  width: 200px;
  background: black;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
}

.pdu-text-wrapper {
  display: flex;
}

.pdu-name {
  width: 100px;
  font-size: 18px;
}

.pdu-ethernet-container {
  margin-left: 9px;
  margin-top: 3px;
  width: 36px;
  height: 36px;
  position: relative;
  float: left;
  background-color: black;
  border-color: white;
  border-radius: 5px;
  border: 2px solid white;
}

.pdu-outlet-container {
  margin-left: 9px;
  margin-top: 3px;
  width: 36px;
  height: 36px;
  position: relative;
  float: left;
  background-color: black;
  border-color: white;
  border-radius: 5px;
  border: 2px solid white;
}

.pdu-logo {
  margin-left: 9px;
  margin-top: 3px;
  float: left;
  position: relative;
  width: 36px;
  height: 36px;
}

.pdu-ethernet-box-1 {
  top: 3px;
  left: 13px;
  width: 10px;
  height: 10px;
  position: absolute;
  background-color: white;
  border-radius: 2px;
}

.pdu-ethernet-box-2 {
  top: 22px;
  left: 4px;
  width: 10px;
  height: 10px;
  position: absolute;
  background-color: white;
  border-radius: 2px;
}

.pdu-ethernet-box-3 {
  top: 22px;
  left: 22px;
  width: 10px;
  height: 10px;
  position: absolute;
  background-color: white;
  border-radius: 2px;
}

.pdu-ethernet-horizontal {
  top: 17px;
  left: 9px;
  width: 18px;
  height: 2px;
  position: absolute;
  background-color: white;
  border-radius: 1px;
}

.pdu-ethernet-vertical-1 {
  top: 17px;
  left: 8px;
  width: 2px;
  height: 5px;
  position: absolute;
  background-color: white;
  border-radius: 1px;
}

.pdu-ethernet-vertical-2 {
  top: 17px;
  left: 26px;
  width: 2px;
  height: 5px;
  position: absolute;
  background-color: white;
  border-radius: 1px;
}

.pdu-ethernet-vertical-3 {
  top: 12px;
  left: 17px;
  width: 2px;
  height: 5px;
  position: absolute;
  background-color: white;
  border-radius: 1px;
}

.pdu-outlet-plug-1 {
  top: 8px;
  left: 7px;
  width: 4px;
  height: 10px;
  position: absolute;
  background-color: white;
  border-radius: 1px;
}

.pdu-outlet-plug-2 {
  top: 8px;
  left: 25px;
  width: 4px;
  height: 10px;
  position: absolute;
  background-color: white;
  border-radius: 1px;
}

.pdu-outlet-plug-3 {
  top: 23px;
  left: 14px;
  width: 8px;
  height: 8px;
  position: absolute;
  background-color: white;
  border-radius: 4px;
}

/* GPU */
.gpu {
  z-index: 10;
}

.gpu-name{
  width: 82px;
  font-size: 18px;
  min-height: 25px;
}

.gpu-exterior {
  float: left;
  position: absolute;
  top: 30px;
  left: -13px;
}

.gpu-exterior-line {
  height: 100px;
  top: 2px;
  width: 5px;
  border: 1px solid black;
  border-radius: 4px;
  background: white;
  position: absolute;
  left: 6px;
  z-index: 10;
}

.gpu-exterior-box-1 {
  height: 15px;
  width: 6px;
  top: 25px;
  border: 2px solid white;
  background: white;
  position: absolute;
}

.gpu-exterior-box-2 {
  height: 15px;
  width: 6px;
  top: 60px;
  border: 2px solid white;
  background: white;
  position: absolute;
  /* left: 5px; */
}

.gpu-interior-box {
  border: 2px solid white;
  border-radius: 12px;
  top: 30px;
  height: 100px;
  width: 164px;
  background: black;
  overflow: hidden;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 10;
}

.gpu-text-wrapper {
  display: flex;
}

/* Electric Truck */
.apparent-module-truck-charger {
  text-align: center;
  font-family: 'russo_oneregular';
  color: #fff;
  position: absolute;
  width: 120px;
  margin-top: 7px;
  z-index: 5;
  line-height: 1.42857143;
  margin-left: -52px;
}

.truck-charger-name {
  font-size: 18px;
  margin-bottom: 2px;
  min-height: 25px;
  margin-top: -3px;
  margin-left: 0px;
  width: 150px;
}

.truck-charger-outer-container {
  text-align: center;
  width: 200px;
}

.truck-charger-inner-container {
  display: inline-block;
  width: 120px;
}

.truck-charger-soc-cell {
  width: 6px;
  height: 17px;
  display: inline-block;
  border-radius: 2px;
  margin: 2px;
}

.truck-charger-block {
  position: absolute;
  top: 111px;
  left: 12px;
  border: 3px solid #fff;
  border-radius: 12px;
  height: 60px;
  background: #000;
  width: 120px;
}

.truck-charger-hbar {
  position: absolute;
  top: 22px;
  left: 18px;
  background: white;
  width: 112px;
  height: 8px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.truck-charger-vbar {
  top: 23px;
  width: 8px;
  height: 115px;
  position: absolute;
  left: 18px;
  background: white;
}

.truck-charger {
  position: absolute;
  left: 56px;
  top: 32px;
  width: 39px;
  height: 5px;
  background: white;
  border-radius: 100px;
}

.truck-charger {
  transform: scale(0.85);
  position: absolute;
}

.electric-truck {
  transform: scale(0.75);
  position: absolute;
  top: 40px;
  left: -5px;
}

.charging-background {
  background-color: #32CD32;
}

.truck-battery-soc-cell {
  width: 4px;
  height: 20px;
  display: inline-block;
  border-radius: 2px;
  margin: 1px;
}

.truck-soc {
  position: absolute;
  top: 0px;
  left: 60px;
}

.truck-icon-1 {
  fill: #56deff;
}

.ev-charger-svg {
  transform: scale(0.85);
  position: absolute;
  top: 20px;
  left: 37px;
  background: #000;
  z-index: 5;
  height: 75px;
  width: 75px;
}

.ev-charger-icon-1 {
  fill: #fff;
}

.ev-charger-icon-2 {
  fill: #fff;
}

.building-name {
  margin-top: 0px;
}

.building-interior-box {
  width: 110px;
  height: 125px;
  align-content: center;
}

.building-outside {
  float: left;
  display: flex;
  margin: auto;
  height: 125px;
  width: 110px;
  border-radius: 1px;
  margin-top: 10px;
  border: 3px solid white;
  background: black;
}

.building-roof {
  position: absolute;
  width: 90px;
  height: 10px;
  border: 3px solid white;
  background: black;
  margin-top: -3px;
  margin-left: 10px;
}

.building-door {
  position: absolute;
  margin-left: 40px;
  bottom: 0px;
  width: 30px;
  height: 45px;
  /* border-radius: 3px; */
  background-color: white;
}

.building-door-inner {
  position: absolute;
  margin-left: 45px;
  bottom: 0px;
  width: 20px;
  height: 40px;
  border-radius: 3px;
  background-color: gray;
}

.building-window-upper {
  position: relative;
  margin-left: 10px;
  margin-top: 15px;
  width: 10px;
  height: 12px;
  background: white;
}

.building-window-lower-container {
  display: flex;
  position: absolute;
  width: 100%;
  height: 20px;
  margin-top: 75px;
}

.building-window-floor-2 {
  position: absolute;
  margin-left: 85px;
  margin-top: 90px;
  width: 10px;
  height: 12px;
  background: white;
}

.power-grid {
  position: relative;
  width: 114px;
  height: 114px;
  background: black;
  -moz-border-radius: 55px;
  -webkit-border-radius: 55px;
  border-radius: 66px;
  border: 3px solid white;
  margin: 1px 1px;
  z-index: 5;
}

.tree-container {
  display: inline-block;
  position: absolute;
  width: 40px;
  height: 80px;
  margin-left: -10px;
  margin-top: 62px;
}

.tree-leaves {
  position: relative;
  width: 40px;
  height: 40px;
  background-color: rgb(28 144 28);
  border-radius: 20px;
}

.tree-trunk{
  position: relative;
  width: 9px;
  height: 42px;
  margin-left: 15px;
  margin-top: -2px;
  background-color: rgb(178 88 16);
}

.generator {
  z-index: 5;
}

.generator-container{
  display: inline-block;
  text-align: center;
}

.generator-block {
  border: 2px solid white;
  border-radius: 12px;
  height: 75px;
  width: 120px;
  background: black;
}

.generator-name {
  font-size: 18px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.generator-readings {
  font-family: "Arial", sans-serif;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 45px;
}

.generator-exhaust {
  position: absolute;
  left: 13.5px;
  top: -22px;
  width: 20px;
  height: 10px;
  border: 3px solid white;
  border-radius: 3px;
}

.generator-exhaust-base {
  position: absolute;
  left: 20px;
  top: -8px;
  width: 10px;
  height: 5px;
  border: 2px solid white;
}

.generator-lines {
  position: relative;
  display: flex;
  width: 100%;
  background: black;
}

.generator-line {
  width: 20px;
  height: 3px;
  border-radius: 2px;
  margin-left: 15px;
  margin-top: 3px;
  background: white;
}

.generator-stand {
  position: absolute;
  width: 5px;
  height: 10px;
  left: 15px;
  top: 76px;
  border-radius: 5px;
  z-index: -1;
  border: 4px solid white;
}

.generator-wheel {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 10px solid white;
  background: black;
  border-radius: 20px;
  left: 95px;
  top: 55px;
}

.grid-tower {
  position: absolute;
  top: 5px;
  left: 8px;
}

.offline {
  color: #474747;
}

.battery-soc-offline {
  opacity: 0.3;
}

.offline-border {
  border-color: #474747;
}

.offline-border-background {
  border-color: #474747;
  background-color: #474747;
}

.site-offline {
  opacity: 0.4;
}

@-webkit-keyframes slide {
    0% { opacity:0; transform: translateX(15vw); }
   20% { opacity:1; transform: translateX(9vw); }
   80% { opacity:1; transform: translateX(-9vw); }
  100% { opacity:0; transform: translateX(-15vw); }
}
@keyframes slide {
    0% { opacity:0; transform: translateX(15vw); }
   20% { opacity:1; transform: translateX(9vw); }
   80% { opacity:1; transform: translateX(-9vw); }
  100% { opacity:0; transform: translateX(-15vw); }
}

.grid-container {
  display: grid;
  grid-template-columns: 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px;
  grid-template-rows:    40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px 40px;
  /*grid-column-gap: 10px;*/
  /*grid-row-gap: 10px;*/
}

.item {
  border: 1px dotted white;
}

.my-popover{
  position: absolute;
  margin-top: 1vh;
  z-index: 100;
}

.arrow{
  display: none;
}

table.meter-view, th.meter-view, td.meter-view {
  /*border: 1px solid white;*/
  border-collapse: collapse;
  font-size: 10pt;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}

th.meter-view, td.meter-view {
  text-overflow: clip;
  padding: 2px 2px;
  text-align: left;
  line-height: 2px;
  overflow: hidden;
  white-space: nowrap;
}

td.meter-view.row-header {
  background-color: #9db2c2;
  border-radius: 5px;
  text-align: right;
  color: #3e4761;
  padding-right: 6px;
  font-weight: 600;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}

td.meter-view:empty {
  visibility: hidden;
}

.meter-view-cell {
  background-color: #616c78;
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}

.meter-view-table.tr:nth-child(even) {background: #616}
.meter-view-table.tr:nth-child(odd) {background: #CCC}






:root {
  --primary-color: white;
  --secondary-color: rgb(61, 68, 73);
  --tertiary-color: rgb(29, 24, 57);
  --highlight-color: #3282b8;

  --dt-status-available-color: greenyellow;
  --dt-status-away-color: lightsalmon;
  --dt-status-offline-color: lightgray;

  --dt-padding: 12px;
  --dt-padding-s: 6px;
  --dt-padding-xs: 2px;

  --dt-border-radius: 3px;

  --dt-background-color-container: #2a3338;
  --dt-border-color: var(--secondary-color);
  --dt-bg-color: var(--highlight-color);
  --dt-text-color: var(--primary-color);
  --dt-bg-active-button: var(--highlight-color);
  --dt-text-color-button: var(--primary-color);
  --dt-text-color-active-button: var(--primary-color);
  --dt-hover-cell-color: var(--highlight-color);
  --dt-even-row-color: var(--secondary-color);
  --dt-odd-row-color: var(--tertiary-color);
  --dt-focus-color: var(--highlight-color);
  --dt-input-background-color: var(--secondary-color);
  --dt-input-color: var(--primary-color);
}

.material-icons {
  font-size: 16px;
}

.darktable-container {
  font-family: sans-serif;
  /*background-color: var(--dt-background-color-container);*/
  border-radius: var(--dt-border-radius);
  color: var(--dt-text-color);
  /* max-width: 1140px; */
  /* min-width: 950px; */
  height: 90%;
  width: 90%;
  margin: 0 auto;
  font-size: 12px;
}

.darktable-container .darktable {
  border-spacing: .5vw 0vh;
  border-collapse: separate;
  width: 100%;
  height: 95%;
}

.darktable-container .darktable,
.darktable-container .darktable th,
.darktable-container .darktable td {
  padding: var(--dt-padding) var(--dt-padding);
}

.darktable-container .darktable th {
  font-weight: 900;
  text-align: right;
  border-bottom: solid 1px var(--dt-border-color);
}

.darktable-container .darktable td {
  min-width: 5vw;
  border-radius:6px;
  margin: 1vw;
  border: solid 2px var(--dt-border-color);
}

.darktable-container .darktable tbody tr:nth-child(even) td:nth-last-child(-n + 4) {
  background-color: var(--dt-even-row-color);
}
.darktable-container .darktable tbody tr:nth-child(odd) td:nth-last-child(-n + 4) {
  background-color: var(--dt-odd-row-color);
}

.darktable-container .darktable tbody tr:hover td:nth-last-child(-n + 4){
  background-color: var(--dt-hover-cell-color);
}

.darktable-container .darktable tbody tr .available::after,
.darktable-container .darktable tbody tr .away::after,
.darktable-container .darktable tbody tr .offline::after {
  display: inline-block;
  vertical-align: middle;
}

.darktable-container .darktable tbody tr .available::after {
  content: "Online";
  color: var(--dt-status-available-color);
}

.darktable-container .darktable tbody tr .away::after {
  content: "Away";
  color: var(--dt-status-away-color);
}

.darktable-container .darktable tbody tr .offline::after {
  content: "Offline";
  color: var(--dt-status-offline-color);
}

.darktable-container .darktable tbody tr .available::before,
.darktable-container .darktable tbody tr .away::before,
.darktable-container .darktable tbody tr .offline::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 50%;
  vertical-align: middle;
}

.darktable-container .darktable tbody tr .available::before {
  background-color: var(--dt-status-available-color);
}

.darktable-container .darktable tbody tr .away::before {
  background-color: var(--dt-status-away-color);
}

.darktable-container .darktable tbody tr .offline::before {
  background-color: var(--dt-status-offline-color);
}

.performance-view {
  margin-left: 20px;
  overflow: auto;
}

.performance-view-body {
  display: inline-block;
  margin-left: 30px;
  margin-top: -1vh;
  margin-bottom: -1vh;
  position: relative;
  height: 10vh;
  width: 95%;
}

.perspective-body {
  float: right;
  margin: 0.5vw;
  position: relative;
}

.timeframe-selector {
  padding-top: 15px;
  padding-left: 30px;
  width: 25%;
  position: relative;
  float: left;
}

.center-header {
  position: relative;
  float: left;
  width: 20%;
  z-index: 5;
  text-align: center;
}

.chart-selector {
  width: 45%;
  position: relative;
  float: right;
  text-align: center;
  margin-top: 1vh;
  z-index: 4;
}

.date-selector {
  float: right;
  margin: 0.5vw;
  position: relative;
  width: 100px;
  overflow: hidden;
}

.selector-btn { 
  display: inline;
  border-width: 0;
  float: left;
  margin: 0.1vw;
  z-index: 5;
  position: relative;
}

.chart-body {
  margin-left: 30px;
  position: relative;
  height: 50vh;
  width: 95%;
}

.billing-timeframe-body {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.base-style {
  display: inline;
  border-width: 0;
  float: left;
  margin: 0.1vw;
  z-index: 5;
  position: relative;
  font-size: 16px;
  font-weight: 500;
}

.form-select {
  height: 25px;
  padding: 0.1vh;
  border-radius: 5px;
  width: unset;
  color: white;
  background: black;
}